
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function categoryVehicleTitleRT () {
    return _createElement('div', { 'className': 'cm_category-vehicle-title' }, this.template === 'active' ? _createElement('div', {
        'className': 'cmTemplate_active',
        'key': '42'
    }, _createElement('h2', {}, this.fitmentSearchTitle)) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cmTemplate_locked',
        'key': '169'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button btn btn-1',
        'onClick': this.changeVehicle,
        'role': 'button',
        'tabIndex': '0'
    }, '\n      Change Vehicle\n    '), _createElement('div', {
        'className': 'cm_vehicle-widget_button btn btn-2',
        'onClick': this.discardVehicle,
        'role': 'button',
        'tabIndex': '0'
    }, '\n      Clear Selection\n    ')))) : null);
}
        export const componentNames = []